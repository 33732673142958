<template>
    <div class="honor">
        <div class="content">
            <div class="content-title">
                <div style="font-size: 2.2rem">获得的荣誉</div>
                <div style="color: #878887;margin-top: 0.8rem">公司秉承“诚信到永远”的工作理念，我们一直在努力</div>
            </div>
            <div class="list">
                <div class="item" v-for="(item,key) in list" :key="key">
                    <div class="container">
                        <img :src='require(`../../assets/imgs/${item.src}`)' :alt="item.title" />
                        <div class="name">{{item.title}}</div>
                        <div class="name2">{{item.title1}}</div>
                    </div>
                </div>
            </div>
            <div class="list1">
                <div class="item1" v-for="(item,key) in list1" :key="key">
                    <div class="container1">
                        <img :src='require(`../../assets/imgs/${item.src}`)' :alt="item.title" />
                        <div class="name1">{{item.title}}</div>
                        <div class="name3">{{item.title1}}</div>
                    </div>
                </div>
            </div>
            <div class="content-title">
                <div style="font-size: 2.2rem">获得的资质</div>
                <div style="color: #878887;margin-top: 0.8rem">公司秉承“诚信到永远”的工作理念，我们一直在努力</div>
            </div>
            <div class="list">
                <div class="item" v-for="(item,key) in list2" :key="key">
                    <div class="container">
                        <img :src='require(`../../assets/imgs/${item.src}`)' :alt="item.title" />
                        <div class="name">{{item.title}}</div>
                        <div class="name2">{{item.title1}}</div>
                    </div>
                </div>
            </div>
            <div class="list1">
                <div class="item1" v-for="(item,key) in list3" :key="key">
                    <div class="container1">
                        <img :src='require(`../../assets/imgs/${item.src}`)' :alt="item.title" />
                        <div class="name1">{{item.title}}</div>
                        <div class="name3">{{item.title1}}</div>
                        <div class="name4">{{item.title2}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
      return{
          list:[
              {src:'rongyu-1.png',title:'湖北省优秀企业'},
              {src:'rongyu-2.png',title:'湖北省优秀软件企业'},
              {src:'rongyu-3.png',title:'优秀安防企业证书'},
              
              {src:'rongyu-5.png',title:'系统集成荣誉证书十强'},
              {src:'rongyu-6.png',title:'武汉十大科技创新企业2017年'},
              {src:'rongyu-7.png',title:'武汉市企业研究开发中心'},
              {src:'rongyu-8.png',title:'抗疫先进企业'},
              {src:'rongyu-9.png',title:'湖北省科技企业家协会',title1:'副会长单位'},
              {src:'rongyu-11.png',title:'中国安全防范产品行业协会',title1:'会员证书'},
              {src:'rongyu-10.png',title:'湖北省安全技术防范行业协会',title1:'理事证书'},
              
              {src:'rongyu-12.png',title:'武汉市安全技术防范行业协会',title1:'副会长单位'},
              {src:'rongyu-4.png',title:'中国建筑业协会智能建筑分会'},
              {src:'rongyu-13.png',title:'湖北省软件行业协会',title1:'常务理事单位'},
              {src:'rongyu-14.png',title:'中国市场学会信用工作委员会'},
              {src:'rongyu-15.png',title:'音视频协会会员证'},
              // {src:'rongyu-16.png',title:'勘察设计协会会员单位'},
              // {src:'rongyu-17.png',title:'湖北中科支部委员会'},
              
          ],
          list1:[
              {src:'rongyu-18.png',title:'3A企业信用等级证书'},
              {src:'rongyu-19.png',title:'2020-2021年度武汉市守合同重信用企业证书'},
              // {src:'rongyu-20.png',title:'中国建筑业协会智能建筑分会常务理事单位'},
              
          ],
          list2:[
              {src:'zizhi-1.png',title:'高新技术企业证书'},
              {src:'zizhi-2.png',title:'软件企业证书'},
              {src:'zizhi-3.png',title:'软件产品证书中科身份认证系统'},
              {src:'zizhi-4.png',title:'安全生产许可证'},
              {src:'zizhi-5.png',title:'CS信息系统建设和服务能力',title1:'等级证书二级'},
              {src:'zizhi-6.png',title:'音视频集成工程企业能力',title1:'等级壹级'},
              {src:'zizhi-7.png',title:'ITSS信息技术服务标准',title1:'符合性证书二级'},
          ],
          list3:[
              {src:'zizhi-8.png',title:'建筑智能化系统设计专项甲级'},
              {src:'zizhi-9.png',title:'电子与智能化工程专业承包壹级',title1:'机电工程施工总承包叄级'},
              {src:'zizhi-9.png',title:'消防设施工程专业承包贰级',title1:'建筑装修装饰工程专业承包贰级',title2:'建筑机电安装工程专业承包贰级'},
              {src:'zizhi-10.png',title:'建筑装饰工程设计专项乙级'},
              {src:'zizhi-11.png',title:'建筑施工劳务资质'},
              {src:'zizhi-12.png',title:'安防工程企业设计施工维护',title1:'能力证书壹级'},
              {src:'zizhi-13.png',title:'CCRC信息安全服务资质',title1:'认证证书二级'},
              {src:'zizhi-14.png',title:'CMMI软件能力成熟度',title1:'模型集成五级'},
              {src:'zizhi-15.png',title:'ISCC诚信管理体系认证证书'},
              {src:'zizhi-16.png',title:'ISCC售后服务认证证书'},
              {src:'zizhi-17.png',title:'ISO20000IT服务管理体系',title1:'认证证书'},
              {src:'zizhi-18.png',title:'ISO27001信息安全管理体系',title1:'认证证书'},
              {src:'zizhi-19.png',title:'ISO9001质量管理体系',title1:'认证证书'},
              {src:'zizhi-20.png',title:'ISO14001环境管理体系',title1:'认证证书'},
              {src:'zizhi-21.png',title:'ISO45001职业健康安全管理',title1:'体系认证证书'},
          ]
      }
  },
  created() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
  },
};
</script>

<style scoped lang="less">
.honor {
    background-color: #F4F6F5;
    height: 100%;
    padding: 2rem 0;
  .content{
    .content-title{
	  margin-top:2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding:1rem 0 2rem 0;
    }
    .list{
      display: grid;
      grid-template-columns: 44rem 44rem 44rem;
      justify-content:center;
      grid-gap: 1rem;
      .item{
        height: 33rem;
        //background: url("../../assets/imgs/frame-2.png") center no-repeat;
		background-color:#fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: center;
        .container{
          height: 22rem;
          width: 40rem;
		  background-color:rgb(246,246,248);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
		  margin-top:3rem;
        }
        img{
          margin:1.5rem auto;
          height: 19rem;
		  width:31rem;
          cursor: pointer;
          &:hover{
            box-shadow:0 0 0.5rem 0.5rem #c9cacd;
          }
        }
      }
      .name{
        font-size: 1.5rem;
        position: relative;
        top: 1rem;
        color: #8C8C8C;
        height: 4rem;
      }
      .name2{
        font-size: 1.5rem;
        position: relative;
        top: 1rem;
        color: #8C8C8C;
        //height: 20px;
      }
    }
    .list1{
	  margin-top:3rem;
      display: grid;
      grid-template-columns: 44rem 44rem 44rem;
        justify-content:center;
      grid-gap: 1rem;
      .item1{
        height: 48rem;
        //background: url("../../assets/imgs/frame-3.png") center no-repeat;
		background-color:#fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        //ustify-content: center;
        .container1{
          height: 36rem;
          width: 40rem;
		  background-color:rgb(246,246,248);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
		  margin-top:2rem;
        }
        img{
		  margin:2rem auto;
          height: 32rem;
          width: 25rem;
          cursor: pointer;
          &:hover{
            box-shadow:0 0 0.5rem 0.5rem #c9cacd;
          }
        }
      }
      .name1{
        font-size: 1.5rem;
        position: relative;
        top: 1rem;
        color: #8C8C8C;
        height: 2rem;
        //width: 26rem;
      }
      .name3{
        font-size: 1.5rem;
        position: relative;
        top: 2rem;
        color: #8C8C8C;
        height: 2rem;
        //width: 26rem;
      }
      .name4{
        font-size: 1.5rem;
        position: relative;
        top: 3rem;
        color: #8C8C8C;
        height: 2rem;
        //width: 26rem;
      }
    }
  }
}
</style>