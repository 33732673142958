<template>
	<!--
   <el-container class="honor">
    <el-header class="honor-top">
      <Header />
    </el-header>
    <el-main class="honor-center">
     <Wall title="荣誉资质" english="Honor And Qualification"/>
     <Honor2 />
    </el-main>
    <div class="honor-footer">
      <Footer />
     </div>
  </el-container>
  -->
  <div>
	   <Header />
	   <Wall title="荣誉资质" english="Honor And Qualification"/>
	   <Honor2 />
	   <Footer />
  </div>
</template>

<script>
import Honor2 from './honorCenter/Honor2.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Wall from "@/views/components/Wall.vue";
export default {
components:{
    Wall,
  Honor2,Header,Footer
}
}
</script>

<style scoped lang="less">
.honor-center{
  padding: 0 !important;
  display: block !important;
}
 //.honor{
 //  &-top{
 //    position: fixed;
 //    top:0;
 //  }
 //}
.honor{
  width: 100%;
  &-top{
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    background-color:#fff;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  &-center{
    position: relative;
    top: 0;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
  }
  .honor-footer{
     top: 0;
     position: relative;
     width: 100%;
     margin: 0;
     padding: 0;
     background-color:#f4f4f4;
     box-sizing: border-box;
  }
}
</style>